import { useNavigation } from 'expo-router'
import { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { FlatGrid } from 'react-native-super-grid'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterGridItem from '../../../components/fosters/FosterGridItem'
import FosterService from '../../../services/fosterService'

const Grid = () => {
  const navigation = useNavigation()
  const { styles } = useStyles(stylesheet)
  const [isRefreshing, setRefreshing] = useState(false)

  const { fetchNextPage, fosterList, refreshFosterList } =
    FosterService.useFosterList()

  useEffect(() => {
    navigation.setOptions({
      title: `My Fosters`,
    })
  }, [navigation])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)

    await refreshFosterList()

    setRefreshing(false)
  }, [refreshFosterList])

  return (
    <View style={styles.root}>
      <FlatGrid
        contentInsetAdjustmentBehavior="automatic"
        data={fosterList}
        itemDimension={175}
        keyExtractor={(foster) => foster.id}
        onEndReached={() => {
          fetchNextPage()
        }}
        onEndReachedThreshold={0.5}
        refreshControl={
          <RefreshControl onRefresh={onRefresh} refreshing={isRefreshing} />
        }
        renderItem={({ item: foster }) => <FosterGridItem foster={foster} />}
        style={{ flex: 1 }}
        testID="foster-grid"
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      backgroundColor: theme.colors.background,
      flex: 1,
    },
  }
})

export default Grid
