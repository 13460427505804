import { useAssets } from 'expo-asset'
import { Image, ImageStyle } from 'expo-image'
import { StyleProp } from 'react-native'
import Species from '../../enums/species'

interface Props {
  blurhash: string | null | undefined
  thumbUri: string | null | undefined
  species: Species
  style: StyleProp<ImageStyle>
  transition?: number
}

const FosterThumbnail = ({
  blurhash,
  thumbUri,
  species,
  style,
  transition,
}: Props) => {
  const [assets] = useAssets([
    require('../../../assets/cat.png'),
    require('../../../assets/dog.png'),
  ])

  return (
    <Image
      accessibilityIgnoresInvertColors={true}
      cachePolicy={'disk'}
      contentFit="cover"
      placeholder={{ blurhash: blurhash || undefined }}
      // @ts-expect-error This field allows for an Asset type, contrary to the type error
      source={
        blurhash
          ? thumbUri
          : species === 'Dog'
            ? // these are defined in the app.config.ts file with the expo-assets plugin
              assets?.[1]
            : assets?.[0]
      }
      style={style}
      testID="foster-image"
      transition={transition || 0}
    />
  )
}

export default FosterThumbnail
