import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { StatsigProviderExpo } from '@statsig/expo-bindings'
import { QueryClientProvider } from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import * as Font from 'expo-font'
import * as NavigationBar from 'expo-navigation-bar'
import { Slot, usePathname, useSegments } from 'expo-router'
import Head from 'expo-router/head'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import { useCallback, useEffect, useState } from 'react'
import {
  Appearance,
  LogBox,
  Platform,
  useColorScheme,
  View,
} from 'react-native'
import { ClickOutsideProvider } from 'react-native-click-outside'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { isMaestro } from 'react-native-is-maestro'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import { PaperProvider } from 'react-native-paper'
import superjson from 'superjson'
import { ApiRoot } from '../../constants'
import SnackbarComponent from '../components/snackbar/SnackbarComponent'
import useQueryClient from '../hooks/useQueryClient'
import { useAppStore } from '../store/useAppStore'
import { darkTheme, lightTheme } from '../styles/theme'
import { doesSessionExist } from '../utils/auth/auth'
import * as datadog from '../utils/datadog/datadog'
import initStatsigClient from '../utils/statsig/statsig'
import trpc from '../utils/trpc'

if (isMaestro()) {
  LogBox.ignoreLogs(['Warning'])
}

SplashScreen.preventAutoHideAsync()

const { statsigClient, statsigCacheWarming } = initStatsigClient()

statsigClient.on('gate_evaluation', (event) => {
  datadog.evaluateFeature(event.gate.name, event.gate.value)
})

statsigClient.initializeAsync()

const Root = () => {
  const [appIsReady, setAppIsReady] = useState(false)
  const colorScheme = useColorScheme()
  const setAuth = useAppStore.use.setAuth()
  const [theme, setTheme] = useState(
    colorScheme === 'dark' ? darkTheme : lightTheme
  )

  // Enables edge-to-edge on Android
  if (Platform.OS === 'android') {
    NavigationBar.setPositionAsync('absolute')
    NavigationBar.setBackgroundColorAsync('rgba(0, 0, 0, 0)')
  }

  Appearance.addChangeListener(({ colorScheme: scheme }) => {
    setTheme(scheme === 'dark' ? darkTheme : lightTheme)
  })

  const queryClient = useQueryClient()
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpLink({
          url: `${ApiRoot()}/trpc`,
        }),
      ],
    })
  )

  useEffect(() => {
    doesSessionExist().then((exists) => {
      setAuth({ isLoggedIn: exists })
    })

    async function prepare() {
      try {
        await Font.loadAsync({
          // Example font, uncomment to use
          // Inter: require('@tamagui/font-inter/otf/Inter-Medium.otf'),
        })

        // Until https://github.com/expo/router/issues/837 is fixed let's give a bit of time to the renderer to get the drawer rendered and hidden
        await new Promise((resolve) => setTimeout(resolve, 50))
      } catch (e) {
        console.warn(e)
      } finally {
        setAppIsReady(true)
      }
    }

    prepare()
  }, [setAuth])

  const onLayoutView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }

  let tanstackDev
  if (__DEV__ && Platform.OS === 'web') {
    tanstackDev = require('@tanstack/react-query-devtools')
  }

  return (
    <>
      {Platform.OS === 'ios' && (
        <Head>
          <meta content="true" property="expo:handoff" />
        </Head>
      )}
      <StatsigProviderExpo
        cacheWarming={statsigCacheWarming}
        client={statsigClient}
      >
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <PaperProvider theme={theme}>
              <GestureHandlerRootView style={{ flex: 1 }}>
                <KeyboardProvider
                  navigationBarTranslucent={true}
                  statusBarTranslucent={true}
                >
                  <View
                    onLayout={onLayoutView}
                    style={{
                      flex: 1,
                      backgroundColor: theme.colors.background,
                    }}
                  >
                    <BottomSheetModalProvider>
                      <ActionSheetProvider>
                        <ClickOutsideProvider>
                          <PageViewTracker />
                          <Slot key="root-slot" />
                        </ClickOutsideProvider>
                      </ActionSheetProvider>
                    </BottomSheetModalProvider>
                  </View>
                  <StatusBar style="auto" />
                  {tanstackDev && (
                    <tanstackDev.ReactQueryDevtools initialIsOpen={false} />
                  )}
                </KeyboardProvider>
              </GestureHandlerRootView>
              <SnackbarComponent />
            </PaperProvider>
          </QueryClientProvider>
        </trpc.Provider>
      </StatsigProviderExpo>
    </>
  )
}

// Keep this one private. It's only used internally by the Root component,
//  and it's encapsulated to avoid re-rendering the entire root component on every page change
const PageViewTracker = () => {
  const pathname = usePathname()
  const segments = useSegments()
  const viewKey = segments.join('/')

  if (Platform.OS !== 'web') {
    datadog.trackPageView(viewKey, pathname)
  }

  return null
}

export default Root
