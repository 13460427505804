import { UserPayload } from '@rescuebase/api/src/router/user/user-router'
import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { useGlobalSearchParams } from 'expo-router'
import Snackbar from '../components/snackbar/Snackbar'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const UserService = {
  useUser() {
    const params = useGlobalSearchParams<{ id: string }>()
    const userId = params.id || ''

    const currentOrganization = useAppStore.use.currentOrganization()

    const { data: user, isLoading } = trpc.user.byId.useQuery({
      id: userId,
      organizationId: currentOrganization?.id,
    })

    return { user, isLoading, currentOrganization }
  },

  useUserList() {
    const organizationId = useAppStore.use.currentOrganization()?.id
    const includeDeactivated = useAppStore.use.includeDeactivated()
    const {
      data: users,
      error,
      isLoading,
    } = trpc.user.list.useQuery({
      includeDeactivated,
      organizationId,
    })
    return { users, error, isLoading }
  },

  useDeactivateMutation() {
    const queryClient = useQueryClient()

    return trpc.user.deactivate.useMutation({
      onSuccess: (_data, { id, organizationId }) => {
        queryClient.invalidateQueries(getQueryKey(trpc.user.list))
        queryClient.setQueryData(
          getQueryKey(
            trpc.user.byId,
            {
              id: id,
              organizationId: organizationId,
            },
            'query'
          ),
          (user: UserPayload | undefined) =>
            user
              ? {
                  ...user,
                  deactivatedAt: new Date(),
                  roles: [],
                }
              : undefined
        )
      },
      onError: () =>
        Snackbar.error('There was a problem deactivating this user'),
    })
  },
  useReactivateMutation() {
    const queryClient = useQueryClient()

    return trpc.user.reactivate.useMutation({
      onSuccess: (_data, { id, organizationId }) => {
        queryClient.invalidateQueries(getQueryKey(trpc.user.list))
        queryClient.setQueryData(
          getQueryKey(
            trpc.user.byId,
            {
              id: id,
              organizationId: organizationId,
            },
            'query'
          ),
          (user: UserPayload | undefined) =>
            user
              ? {
                  ...user,
                  deactivatedAt: null,
                }
              : undefined
        )
      },
      onError: () =>
        Snackbar.error('There was a problem reactivating this user'),
    })
  },
  useUpdateMutation() {
    const queryClient = useQueryClient()
    const currentOrganization = useAppStore.use.currentOrganization()

    return trpc.user.update.useMutation({
      onError: () => {
        Snackbar.error('There was a problem saving this user')
      },
      onSuccess: (updatedData) => {
        queryClient.setQueryData(
          getQueryKey(
            trpc.user.byId,
            {
              id: updatedData.id,
              organizationId: currentOrganization.id,
            },
            'query'
          ),
          () => updatedData
        )
        queryClient.invalidateQueries(getQueryKey(trpc.user.list))
      },
    })
  },
  useIsSaving() {
    const mutationKey = getQueryKey(trpc.user.update)
    return useIsMutating({ mutationKey }) > 0
  },
}

export default UserService
