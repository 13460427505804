import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import UserService from '../../../services/userService'

const DeactivatedBanner = () => {
  const { styles } = useStyles(stylesheet)
  const { user, currentOrganization } = UserService.useUser()

  const reactivateMutation = UserService.useReactivateMutation()

  if (!user || !user.deactivatedAt) return

  const reactivate = () => {
    if (!user) return
    reactivateMutation.mutate({
      id: user.id,
      organizationId: currentOrganization.id,
    })
  }

  return (
    <View style={styles.banner} testID="deactivated-banner">
      <View style={styles.bannerContent}>
        <Text>{user.firstName}'s account has been deactivated.</Text>
        <Button
          disabled={reactivateMutation.isLoading}
          labelStyle={styles.bannerButton}
          loading={reactivateMutation.isLoading}
          onPress={reactivate}
        >
          Reactivate
        </Button>
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  banner: {
    backgroundColor: theme.colors.errorContainer,
    borderColor: theme.colors.error,
    borderRadius: theme.tokens.containerBorderRadius,
    borderWidth: 1,
    paddingBottom: theme.tokens.spacing[3],
    paddingLeft: theme.tokens.spacing[4],
    paddingRight: theme.tokens.spacing[4],
    paddingTop: theme.tokens.spacing[3],
  },
  bannerButton: {
    color: theme.colors.onSurface,
  },
  bannerContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.tokens.spacing[3],
    paddingRight: theme.tokens.spacing[3],
  },
}))

export default DeactivatedBanner
