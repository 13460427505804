import { useState } from 'react'
import DocumentScanner, {
  ResponseType,
  ScanDocumentResponseStatus,
} from 'react-native-document-scanner-plugin'
import { FAB } from 'react-native-paper'
import Snackbar from '../snackbar/Snackbar'
import ScanDocumentAction from './actions/ScanDocumentAction'
import usePrintPdfAction from './actions/usePrintPdfAction'

type FosterActionButtonProps = {
  currentOrganizationId: string
  fosterId: string
}

const FosterActionButton = ({
  currentOrganizationId,
  fosterId,
}: FosterActionButtonProps) => {
  const [state, setState] = useState({ open: false })
  const [scanResults, setScanResults] = useState<string[] | null>(null)

  const pdfAction = usePrintPdfAction(fosterId, currentOrganizationId)
  const onStateChange = ({ open }: { open: boolean }) => setState({ open })

  const { open } = state

  // We prefetch some things when the FAB is opened to reduce the perceived latency for the end-user
  if (open) {
    pdfAction.prefetch()
  }

  const scanDocument = async () => {
    const result = await DocumentScanner.scanDocument({
      croppedImageQuality: 100,
      responseType: ResponseType.ImageFilePath,
    })

    if (result.status === ScanDocumentResponseStatus.Success) {
      if (!result.scannedImages) {
        Snackbar.error(
          'There was an error scanning the documents. Please try again.'
        )
        return
      }

      setScanResults(result.scannedImages)
    }
  }

  return (
    <>
      <FAB.Group
        actions={[
          {
            icon: 'book-information-variant',
            label: 'Print Cover Sheet',
            onPress: async () => {
              await pdfAction.openPDF()
            },
            testID: 'print-cover-sheet-action',
          },
          {
            icon: 'scanner',
            label: 'Scan Documents',
            onPress: () => scanDocument(),
            testID: 'scan-documents-action',
          },
        ]}
        icon={open ? 'close' : 'menu'}
        onStateChange={onStateChange}
        open={open}
        style={{
          position: 'absolute',
          // right: 10,
          // bottom: 55,
        }}
        testID="foster-action-button"
        visible
      />
      {scanResults?.length && (
        <ScanDocumentAction
          currentOrganizationId={currentOrganizationId}
          fosterId={fosterId}
          onDismiss={() => setScanResults(null)}
          scanUris={scanResults}
        />
      )}
    </>
  )
}

export default FosterActionButton
