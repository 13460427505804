import { LinearGradient } from 'expo-linear-gradient'
import { Link } from 'expo-router'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../enums/species'
import FosterThumbnail from './FosterThumbnail'

interface Props {
  foster: {
    id: string
    blurhash: string | null
    thumbUri: string | null
    species: 'Dog' | 'Cat' | null
    name: string
    user: {
      firstName: string
      lastName: string
    }
  }
}

const FosterGridItem = ({ foster }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <Link
      asChild
      href={{
        pathname: '/fosters/[id]',
        params: { id: foster.id },
      }}
      testID={`foster-link-${foster.id}`}
    >
      <TouchableRipple accessibilityRole="button">
        <View style={styles.imageContainer}>
          <FosterThumbnail
            blurhash={foster.blurhash}
            species={foster.species as Species}
            style={StyleSheet.absoluteFill}
            thumbUri={foster.thumbUri}
            transition={400}
          />
          <LinearGradient
            colors={[
              'transparent',
              'rgba(0,0,0,0.5)',
              'rgba(0,0,0,0.7)',
              'rgba(0,0,0,0.9)',
            ]}
            style={styles.gradientContainer}
          />
          <View style={styles.cardLabelContainer}>
            <Text
              numberOfLines={1}
              style={[styles.text, { opacity: 1 }]}
              testID={`foster-name-${foster.id}`}
              variant="titleLarge"
            >
              {foster.name}
            </Text>
            <Text
              style={styles.text}
              testID={`user-name-${foster.id}`}
              variant="bodyMedium"
            >
              {foster.user.firstName} {foster.user.lastName}
            </Text>
          </View>
        </View>
      </TouchableRipple>
    </Link>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    cardLabelContainer: {
      bottom: 0,
      padding: theme.tokens.spacing[3],
      position: 'absolute',
    },
    gradientContainer: {
      bottom: 0,
      height: '35%',
      position: 'absolute',
      width: '100%',
    },
    imageContainer: {
      borderRadius: theme.tokens.containerBorderRadius,
      height: 200,
      overflow: 'hidden',
    },
    text: {
      color: 'white',
    },
  }
})

export default memo(FosterGridItem, (previous, next) => {
  return (
    previous.foster.id === next.foster.id &&
    previous.foster.thumbUri === next.foster.thumbUri &&
    previous.foster.blurhash === next.foster.blurhash &&
    previous.foster.name === next.foster.name &&
    previous.foster.user.firstName === next.foster.user.firstName &&
    previous.foster.user.lastName === next.foster.user.lastName
  )
})
